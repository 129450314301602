import "@iproov/web"; // includes the @iproov/web client into your app
import { createRef, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import * as jose from "jose";
import { Precheck } from "./Precheck";
import { Complete } from "./Complete";
import { Failed } from "./Failed";

export function IProov() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [sessionToken, setSessionToken] = useState(null);
  const [claims, setClaims] = useState(null);
  const iproovRef = useCallback(() => createRef(), []);

  useEffect(() => {
    const checkSession = async () => {
      if (sessionToken) {
        return;
      }
      const token = searchParams.get("session_token");
      const state = searchParams.get("state");
      const claims = jose.decodeJwt(token);
      setClaims(claims);
      setSessionToken(claims.token);
    };

    checkSession();
  }, [searchParams, sessionToken]);

  return (
    <div className="App">
      {sessionToken === null ? (
        <></>
      ) : (
        <>
          <iproov-me
            ref={iproovRef}
            token={sessionToken}
            custom_title="Verify your facial biometrics"
            base_url="https://au.rp.secure.iproov.me"
            logo={"https://i.imgur.com/Kkjv6hY.png"}
            filter="vibrant"
            header_background_color="rgba(55,65,81, 1)"
            footer_background_color="rgba(55,65,81, 0.68)"
            progress_bar_color="rgba(55,65,81, 1)"
            close_button="https://i.imgur.com/hj4xR2L.png"
          >
            <div slot="ready">
              <Precheck name={claims.name} />
            </div>
            <div slot="button" className="bg-slate-600 py-12 px-12">
              <button
                type="button"
                className="block w-full py-3 px-5 text-center bg-white border border-transparent rounded-md shadow-md text-base font-medium text-slate-700 hover:bg-gray-50 sm:inline-block sm:w-auto"
                href="#"
              >
                Start face scan
              </button>
            </div>
            <div slot="passed">
              <Complete searchParams={searchParams} claims={claims} />
            </div>
            <div slot="failed">
              <Failed />
            </div>
          </iproov-me>
        </>
      )}
    </div>
  );
}
