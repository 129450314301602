export const Failed = () => {
  return (
    <div className="relative bg-gray-50 h-screen overflow-hidden">
      <div className="relative h-full pt-6 pb-16 sm:pb-24">
        <main className="mt-16 flex mx-auto max-w-7xl px-4 sm:mt-24">
          <div className="text-center ">
            <div className="flex-none">
              <img
                className="h-20 w-20 mx-auto m-12 object-center rounded-full shadow-2xl"
                src="https://i.imgur.com/Kkjv6hY.png"
                alt=""
              />
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">Uh oh...</span>{" "}
                <span className="block text-slate-600 xl:inline">
                  We could not verify your identity
                </span>
              </h1>
              <p className="mt-8 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Ensure that your entire face is visible under good lighting and
                you keep still. Please exit the process and try again.
                <p slot="reason"></p>
              </p>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
