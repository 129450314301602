export const Complete = ({ searchParams, claims }) => {
  return (
    <div className="relative bg-gray-50 h-screen">
      <div className="relative h-full pt-6 pb-16 sm:pb-24">
        <main className="mt-16 flex mx-auto max-w-7xl px-4 sm:mt-24">
          <div className="text-center ">
            <div className="flex-none">
              <img
                className="h-20 w-20 mx-auto m-12 object-center rounded-full shadow-2xl"
                src="https://i.imgur.com/Kkjv6hY.png"
                alt=""
              />
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">All done!</span>{" "}
                <span className="block text-slate-600 xl:inline">
                  Face verification complete
                </span>
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                By clicking continue, you allow Kakapo Transport to use your
                personal information to supply you with a digital drivers
                license that will be stored on the MATTR Wallet.
              </p>
            </div>
            <div className="flex-none mt-28 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
              <div className="rounded-md shadow">
                <button
                  onClick={() => {
                    const url = new URL(
                      `https://${claims.iss}/continue?state=${searchParams.get(
                        "state"
                      )}&session_token=${searchParams.get("session_token")}`
                    );
                    window.location.href = url;
                  }}
                  className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-slate-600 hover:bg-slate-700 md:py-4 md:text-lg md:px-10"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
