import "./App.css";
import "@iproov/web"; // includes the @iproov/web client into your app
import { Routes, Route } from "react-router-dom";
import { IProov } from "./components/Iproov";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<IProov />} />
      </Routes>
    </div>
  );
}

export default App;
